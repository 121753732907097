import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { MessageService } from '@appServices/message.service';

export enum ToastType {
  light = 'light',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private isToastPresented = false;

  private readonly defaultToastOptions: ToastOptions = {
    message: '',
    color: 'light',
    position: 'top',
    buttons: [
      {
        text: 'Ясно',
        role: 'cancel',
        handler: () => this.dismissToast(),
      },
    ],
  };

  private toastInstance: HTMLIonToastElement;

  constructor(private toastController: ToastController, private messageService: MessageService) {
    this.createToast();
  }

  public showToast(header?: string, message?: string, toastType?: ToastType): void {
    if (header) {
      this.toastInstance.header = header;
    }

    if (message) {
      this.toastInstance.message = message;
    }

    if (toastType) {
      this.toastInstance.color = toastType;
    }

    this.toastInstance?.present().then(() => {
      this.isToastPresented = true;
    });
  }

  public addButtonWithHandler(buttonName: string, handler: () => void): void {
    this.toastInstance.buttons = [
      {
        text: 'Отмена',
        role: 'cancel',
        handler: () => {
          this.dismissToast();
          this.resetDefaults();
        },
      },
    ];

    this.toastInstance.buttons.push({
      text: buttonName,
      role: 'ok',
      handler: () => {
        handler();
        this.dismissToast();
        this.resetDefaults();
      },
    });
  }

  public dismissToast(): void {
    if (this.isToastPresented) {
      this.toastInstance.dismiss().then(() => this.messageService.dismissLoading());
      this.isToastPresented = false;
    }
  }

  private createToast(): void {
    this.toastController.create(this.defaultToastOptions).then((loading) => {
      this.toastInstance = loading;
      this.toastInstance.onDidDismiss().then(() => this.createToast());
    });
  }

  private resetDefaults(): void {
    for (let key in Object.keys(this.defaultToastOptions)) {
      if (this.defaultToastOptions[key]) {
        this.toastInstance[key] = this.defaultToastOptions[key];
      }
    }
  }
}
