import { Component, OnInit } from '@angular/core';
import { AppService } from '@appServices/app.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.page.html',
})
export class UnauthorizedPage implements OnInit {
  constructor(public app: AppService) {}

  ngOnInit() {}
}
