import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'nc-501',
  templateUrl: './underconstruction.page.html',
  styleUrls: ['./underconstruction.page.scss'],
})
export class UnderconstructionPage implements OnInit {
  @Input() mode: string = 'page';

  constructor(private menu: MenuController, private router: Router) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.menu.enable(false, 'end');
    this.menu.enable(true, 'workspace');
  }

  gotoMain() {
    this.router.navigateByUrl('/ws', { replaceUrl: true });
  }
}
