import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SrvService } from '@appServices/srv.service';

@Injectable({
  providedIn: 'root',
})
export class DctService {
  currentOverride: {};

  constructor(private srv: SrvService) {}

  getEntity$(dctkey: string, code: any): Observable<any> {
    let id = code instanceof Object ? code.id : code;
    return this.srv.dct$(dctkey).pipe(
      filter((dct) => !!dct),
      take(1),
      // tap( dct => console.log('DCT fetched', dctkey, id, dct, dct[id])),
      map((dct) => dct[id])
    );
  }

  getParamValue$(key: string, code: string, param: string): Observable<string> {
    return this.getEntity$(key, code).pipe(
      map((ent) => {
        if (ent && ent[param]) return ent[param];
        else return `[?].${key}.${code}`;
      })
    );
  }
}
