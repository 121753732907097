import { Injectable } from '@angular/core';

const TERM_DCT = {
  role_ui: 'Пользовательская роль',
  'ui.ent.role_ui.lbl.nom': 'Пользовательская роль',
  'ui.ent.role_ui.lbl.nom.plural': 'Пользовательские роли',
  'ui.ent.role_ui.lbl.genitive': 'Пользовательской роли',
  'ui.ent.role_ui.lbl.genitive.plural': 'Пользовательских ролей',
  'ui.ent.role_ui.lbl.accusative': 'Пользовательскую роль',
  'ui.ent.role_ui.lbl.accusative.plural': 'Пользовательские роли',

  'ui.ent.organization_info.title': 'Реквизиты организации',
  'ui.ent.organization_info.lbl.nom': 'Реквизиты организации',
  'ui.ent.organization_info.lbl.genitive': 'Реквизитов организации',
  'ui.ent.organization_info.lbl.genitive.plural': 'Реквизитов организации',

  'ui.page.database.title': 'Данные',
  'ui.page.database.icon': 'cube',
  'ui.page.map.title': 'Карта',
  'ui.page.map.icon': 'map',
  // 'ui.page.reports.title': 'Отчётность',
  // 'ui.page.reports.icon': 'reader',
  'ui.page.licenses.title': 'Лицензии',
  'ui.page.licenses.icon': 'ribbon',
  'ui.page.wasteplaces.title': 'Места накопления',
  'ui.page.wasteplaces.icon': 'trash',
  'ui.page.vehicles.title': 'Транспортные средства',
  'ui.page.vehicles.icon': 'bus',
  'ui.page.facilities.title': 'Объекты обращения',
  'ui.page.facilities.icon': 'business',
  'ui.page.organizations.title': 'Организации',
  'ui.page.organizations.icon': 'briefcase',
  'ui.page.summary.title': 'Сводка',
  'ui.page.summary.icon': 'pie-chart',
  // 'ui.page.bids.title': 'Заявки с отходообразователем',
  // 'ui.page.bids.icon': 'document',
  // 'ui.page.bids-ws.title': 'Заявки с отходообразователем',
  // 'ui.page.bids-ws.icon': 'document',
  // 'ui.page.modeling.title': 'Моделирование',
  // 'ui.page.modeling.icon': 'map',
  // 'ui.page.telemetry.title': 'Настройка телеметрии',
  // 'ui.page.telemetry.icon': 'radio',
  // 'ui.page.waste-conflicts.title': 'Конфликты',
  // 'ui.page.waste-conflicts.icon': 'alert-circle',
  // 'ui.page.orders-wastesource.title': 'Заказы на выгрузку',
  // 'ui.page.orders-wastesource.icon': 'document-outline',
  // 'ui.page.orders-recycling.title': 'Заказы на переработку',
  // 'ui.page.orders-recycling.icon': 'document-outline',
  // 'ui.page.orders-transportation.title': 'Заказы на транспортирование',
  // 'ui.page.orders-transportation.icon': 'document-outline',
  // 'ui.page.driver-tasks.icon': 'extension-puzzle',
  'ui.page.analytics.title': 'Аналитика',
  'ui.page.analytics.icon': 'bar-chart',
  // 'ui.page.datafiles.title':'Федеральная схема',
  // 'ui.page.datafiles.icon': 'archive',
  // 'ui.page.recycle-reports.title':'Акты об обращении с отходами',
  // 'ui.page.recycle-reports.icon': 'repeat-sharp',
  // 'ui.page.contracts-transportation.icon': 'document-text',
  // 'ui.page.contracts-waste-generator.icon': 'document-text',
  'ui.page.contract-offers.icon': 'document-text',
  'ui.page.contract-applications.icon': 'document',
  'ui.page.contract.icon': 'reader-outline',
  // 'ui.page.contracts-complex-service.icon': 'document-text',
  // 'ui.page.responds-complex-service.icon': 'reader-outline',
  // 'ui.page.responds-recycling.icon': 'reader-outline',
  // 'ui.page.responds-transportation.icon': 'reader-outline',
  // 'ui.page.charges-complex-service.icon': 'albums',
  // 'ui.page.charges-recycling.icon': 'albums',
  // 'ui.page.charges-transportation.icon': 'albums',
  // 'ui.page.subcontracts-waste-generator.icon': 'document-text-outline',

  'ui.ent.wasteplace.title': 'Место накопления отходов',
  'ui.ent.vehicle.title': 'Сведения о транспортном средстве',
  'ui.ent.license.title': 'Лицензия оператора',
  'ui.ent.facility.title': 'Сведения об объекте обращения',
  // 'ui.ent.wastereport_operation.title': 'Сведения об операции с отходами',
  'ui.ent.organization.title': 'Сведения об организации',
  'ui.ent.bid.title': 'Заявка с отходообразователем',
  // 'ui.ent.waste_conflict.title':'Конфликт',
  // 'ui.ent.order_waste_source.title':'Заказ на выгрузку',
  // 'ui.ent.order_recycling.title':'Заказ на переработку',
  // 'ui.ent.order_transportation.title':'Заказ на транспортирование',
  // 'ui.ent.trip_report.title':'Отчет по задаче',
  // 'ui.ent.integration_weighing.title':'Факт взвешивания',
  // 'ui.ent.waste_container.title':'Грузовое место для отходов',
  // 'ui.ent.recycle_report.title':'Акт об обращении с отходами',

  // nominative - nom        кто? что?
  // genitive       НЕТ      кого? чего?
  // dative         ДАТЬ     кому? чему?
  // accusative     ВИЖУ     кого? что?
  // ablative       ДОВОЛЕН  кем? чем?
  // prepositional  ДУМАЮ    о ком? о чем?
  'ui.ent.wasteplace.lbl.nom': 'Место накопления',
  'ui.ent.wasteplace.lbl.nom.plural': 'Места накопления',
  'ui.ent.wasteplace.lbl.genitive': 'Места накопления',
  'ui.ent.wasteplace.lbl.genitive.plural': 'Мест накопления',
  'ui.ent.wasteplace.lbl.accusative': 'Место накопления',
  'ui.ent.wasteplace.lbl.accusative.plural': 'Места накопления',

  'ui.ent.license.lbl.nom': 'Лицензия',
  'ui.ent.license.lbl.nom.plural': 'Лицензии',
  'ui.ent.license.lbl.genitive': 'Лицензии',
  'ui.ent.license.lbl.genitive.plural': 'Лицензий',
  'ui.ent.license.lbl.accusative': 'Лицензию',
  'ui.ent.license.lbl.accusative.plural': 'Лицензии',

  'ui.ent.user.lbl.nom': 'Пользователь',
  'ui.ent.user.lbl.nom.plural': 'Пользователи',
  'ui.ent.user.lbl.genitive': 'Пользователя',
  'ui.ent.user.lbl.genitive.plural': 'Пользователей',
  'ui.ent.user.lbl.accusative': 'Пользователя',
  'ui.ent.user.lbl.accusative.plural': 'Пользователей',

  'ui.ent.vehicle.gnum': 'Госномер',
  'ui.ent.user.title': 'Пользователь',
  'ui.ent.user_profile.title': 'Пользователь',
};

@Injectable({
  providedIn: 'root',
})
export class TermService {
  currentOverride: {};

  getTerm(code: string, override?): any {
    let term = null;
    if (override && typeof override === 'object') term = override[code];
    if (!term && this.currentOverride) term = this.currentOverride[code];
    if (!term) term = TERM_DCT[code] || (typeof override === 'string' ? override : null);
    return term;
  }
}
