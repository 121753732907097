import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DebugMode {
  /** Флаг: режим debug включен */
  public static isOn: boolean;

  /* Инстанс сервиса */
  private static _instance: DebugMode;

  private constructor() {
    DebugMode.isOn = environment.debugMode;

    if (!environment.production && !DebugMode.isOn) {
      console.log('Для вывода отладочной информации установите debugMode в true (environment.ts)');
    }
  }

  /** Возвращает инстанс */
  public static get instance(): DebugMode {
    if (!this._instance) {
      this._instance = new DebugMode();
    }
    return this._instance;
  }

  /**
   * Добавляет к объекту window объект для отладки
   *
   * @param name - имя по которому можно обратиться к объекту через window[name]
   * @param whatToApply - объект, который будет храниться в window[name]
   */
  public static applyToWindow(name: string, whatToApply: any): void {
    if (!DebugMode.isOn) return;
    if (window) {
      window[name] = whatToApply;
    }
  }
}
