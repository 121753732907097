import { Component, HostBinding } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { AppService, IAppLayer } from '@appServices/app.service';
import { Title } from '@angular/platform-browser';
import { DebugMode } from '@appServices/debug.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public layers$ = this.appService.layersSubject;

  constructor(
    public appService: AppService,
    private platform: Platform,
    private menu: MenuController,
    private titleService: Title,
    private debugMode: DebugMode
  ) {
    this.platform.ready().then(() => {
      const title = this.appService.visualEntranceStyle?.tabTitle
        ? this.appService.visualEntranceStyle.tabTitle
        : 'ОССиГ';

      this.titleService.setTitle(title);
    });
  }

  @HostBinding('style.--nci-card-border')
  get getCardBorder() {
    return this.appService.visualStyle.hasUIElement_frames ? '1px solid var(--ion-color-step-150, #d9d9d9)' : 'none';
  }

  @HostBinding('style.--nci-card-border-radius')
  get getCardBorderRadius() {
    return this.appService.visualStyle.hasUIElement_frames ? '4px' : '0';
  }

  public focusOnLayer(layer: IAppLayer, layers: IAppLayer[]) {
    for (let i = layers.indexOf(layer) + 1; i < layers.length; i++) {
      let aboveLayer = layers[i];
      if (aboveLayer.modal) {
        let wrapper = aboveLayer.modal.getElementsByClassName('modal-wrapper');
        if (wrapper && wrapper[0]) wrapper[0].style.display = 'none';
      }
    }
    layer.focused = true;
  }

  public focusOnTopLayer(layers: IAppLayer[]) {
    layers.forEach((layer) => {
      layer.focused = false;
      if (layer.modal) {
        let wrapper = layer.modal.getElementsByClassName('modal-wrapper');
        if (wrapper && wrapper[0]) wrapper[0].style.display = 'inherit';
      }
    });
  }

  public tryToStepBackToLayer(layer: IAppLayer, layers: IAppLayer[]) {
    if (!layers?.length || !layers.includes(layer)) return;

    let topLayerId = layers.length - 1;
    let layerId = layers.indexOf(layer);

    if (topLayerId > layerId) {
      this.appService.tryToCloseLayer(topLayerId, layerId, layers);
    }

    layer.selected = true;
  }

  public tryToCloseTopLayer(layers: IAppLayer[]) {
    let topLayerId = layers.length - 1;
    let topLayerSelectedId: number;

    if (layers.length > 1) {
      topLayerSelectedId = topLayerId - 1;
      layers[topLayerSelectedId].selected = true;
    } else {
      topLayerSelectedId = topLayerId;
    }

    if (topLayerId) {
      this.appService.tryToCloseLayer(topLayerId, topLayerSelectedId, layers);
    }
  }

  public getLayerIcon(layer: IAppLayer): string {
    return layer.icon;
  }
}
