import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ContentToSignModel } from '@appModels/content-to-sign-model';
import { EntityModel } from '@appModels/entity-model';
import { EntitySchemaModel } from '@appModels/entity-schema-model';
import { ItemToTransitModel } from '@appModels/item-to-transit-model';
import { PostTrackingDataModel } from '@appModels/post-tracking-data-model';
import { ResponseModel } from '@appModels/response-model';
import { fileDownloaderFn } from '../app.utils';
import { IActTask } from '../ws/pages/analytics/analytics.types';
import { HttpService } from '@appServices/http.service';
import { Urls } from '../urls';
import { MessageService } from '@appServices/message.service';
import { EntityRequestObjectModel } from '@appModels/entity-request-object-model';
import { MethodType } from '@appModels/method-type';
import { ToastService, ToastType } from '@appServices/toast.service';

const MAX_PAGE_SIZE = 9999;

const HEADERS = new HttpHeaders()
  .set('Content-Type', 'application/vnd.api+json')
  .set('Accept', 'application/vnd.api+json');

const ACTION_HEADERS = new HttpHeaders().set('Content-Type', 'application/json');

let counter = 0;

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
let __cash = {};
// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
let __cash$: { [key: string]: Subject<any> } = {};

[
  'wasteplace_norm_doc_type',
  'statused_statuses',
  'statused_transits',
  'statused_transit_groups',
  'facility_planed_work',
  'facility_operation_types',
  'facility_operation_mobility_types',
  'facility_waste_units',
  'facility_onvos_categories',
  'vehicle_types',
  'wastereport_operation_types',
  'license_waste_types',
  'license_statuses',
  'accrual_status',
  'state_offer',
  'container_type',
  'facility_onvos_categories',
  'organization_info_vat_cond',
  'waste_container_type_dangerous_classes',
  'trip_part_type',
  'trip_report_type',
  'trip_report_state',
  'trip_facility_report_state',
  'task_state',
  'region',
  'class_fkko',
  'fkko',
  'claim_payer_waste_source',
  'claim_payer_facility',
  'claim_payer_transporter',
  'contract_operation_types',
  'waste_conflict_types',
  'recyclereports_waste_types',
  'contract_waste_generator_years',
  'filter_preset',
  'payment_state',
  'contract_offer_business_types',
  'transport_type',
  'order_item_type',
  'payment_types',
  'payment_reservation_states',
].forEach((key) => {
  __cash$[`dct.${key}`] = new BehaviorSubject(null);
  __cash$[`all.${key}`] = new BehaviorSubject([]);
});

// @ts-ignore
if (window) window.$srv = { cash: __cash, _$: __cash$ };

@Injectable({
  providedIn: 'root',
})
export class SrvService {
  public toastPresented$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public getTerm: (code: string) => string;

  constructor(
    private http: HttpClient,
    private toast: ToastController,
    private httpService: HttpService,
    private messageService: MessageService,
    private toastService: ToastService
  ) {
    // @ts-ignore
    if (window) window.srv = this;
  }

  public dct$(dctkey) {
    if (!__cash$[`dct.${dctkey}`]) console.warn(`Словарь не определен: ${dctkey}`);
    return __cash$[`dct.${dctkey}`];
  }

  public getEntSnapshot(ent) {
    if (ent && ent.$isVnd) {
      if (!ent.$snapshot && ent.$makeup) ent.$makeup();
      return ent.$snapshot;
    } else return ent;
  }

  public fetchEntSigningData$(
    entity: ItemToTransitModel | EntityModel,
    o?: { captionErr?: string }
  ): Observable<ContentToSignModel> {
    return this.http
      .get<ResponseModel>(`/signatureapi/v1/${entity.type}/${entity.id}/`, {
        headers: HEADERS,
        // @ts-ignore
        responseType: 'text',
      })
      .pipe(
        map((response) => {
          let parsedResponse;
          let str;
          try {
            parsedResponse = JSON.parse(response.toString());
            str = parsedResponse.data.attributes.__str__;
          } catch (e) {
            parsedResponse = {};
          }
          return {
            name: str,
            object: parsedResponse,
            content: response.toString(),
          } as ContentToSignModel;
        }),
        tap((contentToSign: ContentToSignModel) => {
          entity.$contentToSign = contentToSign;
        }),
        catchError((e) => {
          let message = o.captionErr;
          if (e.error) {
            if (e.error.errors) {
              if (e.error.errors instanceof Array)
                message = e.error.errors.reduce(
                  (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                  ''
                );
              else if (e.error.errors.error)
                message =
                  typeof e.error.errors.error === 'string'
                    ? e.error.errors.error
                    : JSON.stringify(e.error.errors.error);
            }
          }
          return from(
            this.toast
              .create({
                header: 'Ошибка при обращении к серверу',
                message: `${++counter}. ${message}`,
                color: 'danger',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present();
                this.toastPresented$.next(true);
              })
              .then(() => {
                throw e;
              })
          );
        })
      ) as Observable<ContentToSignModel>;
  }

  public transitEntities$(
    itemsToTransit: ItemToTransitModel[],
    o: {
      transitid?: string;
      message?: string;
      isSilent?: boolean;
      captionErr?: string;
      isGroup?: boolean;
    } = {}
  ): Observable<ItemToTransitModel[]> {
    const data = itemsToTransit.map((itemToTransit) => {
      let itemData = {
        id: itemToTransit.id,
        type: itemToTransit.type,
        transit: itemToTransit.$transit || o.transitid,
        transit_group: itemToTransit.$transit_groups,
        payload: {} as any,
      };
      if (o.isGroup) {
        itemToTransit.$transitReport = { status: 'waiting' };
      }
      if (itemToTransit.$sign) {
        itemData.payload.sign = itemToTransit.$sign;
      }
      if (itemToTransit.$file_name) {
        itemData.payload.file_name = itemToTransit.$file_name;
      }
      if (itemToTransit.$transitForm) {
        itemData.payload.form = itemToTransit.$transitForm;
      }
      return itemData;
    });
    return this.commonActRequest$({
      data: {
        context: 'main',
        action: o.isGroup ? 'transit_group' : 'transit',
        data,
      },
      ...o,
      isSilent: false,
    }).pipe(
      map((response) => {
        const reports = response?.data;
        if (reports?.length) {
          reports.forEach((transitReport) => {
            if (transitReport.error) {
              this.toastService.showToast('Ошибка', transitReport.error, ToastType.danger);
              throw new Error(transitReport.error);
            }
            if (transitReport.transit) transitReport.$transit = transitReport.transit;
            const signedItem = itemsToTransit.find(
              (_item) => _item.id === transitReport.id && _item.type === transitReport.type
            );
            if (signedItem) {
              signedItem.$isSuccess = !transitReport.error;
              signedItem.$transitReport = transitReport;
            }
          });

          if (o.isGroup) return reports as ItemToTransitModel[];
        }

        itemsToTransit.forEach((item) => {
          if (!item.$transitReport) {
            item.$isSuccess = false;
            item.$transitReport = {
              status: 'error',
              error: 'Некорректный ответ сервера',
            };
          }
        });

        return itemsToTransit as ItemToTransitModel[];
      }),
      catchError((e) => {
        console.error(e);
        itemsToTransit.forEach((_item: ItemToTransitModel) => {
          _item.$isSuccess = false;
          if (e instanceof HttpErrorResponse)
            _item.$transitReport = {
              status: 'error',
              error: e.message,
              detail: e.error,
            };
        });
        return itemsToTransit;
      })
    ) as Observable<ItemToTransitModel[]>;
  }

  /**
   * Получает контракты для заявки
   *
   * @param applicationId - ид заявки
   */
  public getContractsByApplicationId(applicationId: number) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params = { contract_application: applicationId };
    return this.httpService.getObservable(Urls.getApplicationContractsUrl(), { params });
  }

  //TODO: эту часть необходимо пересмотреть и возможно объеденить (entityPrint, actionPrint, signPrint)
  /**
   * @param entId
   * @param entType
   * @param stencilFieldKey
   * @param stencilId
   * @param isSilent
   * @param forView  Флаг для запроса версии документа со штампами. По умолчанию false
   * @returns Observable<{ blob: Blob; filename: string; mime: string }>
   */
  public entityPrint$(
    entId: string,
    entType: string,
    stencilFieldKey: string,
    stencilId: string = null,
    isSilent = false,
    forView = false
  ): Observable<{ blob: Blob; filename: string; mime: string }> {
    const data = {
      id: entId,
      type: entType,
      field: stencilFieldKey,
      stencil_id: stencilId,
      for_view: forView,
    };

    return this.actionPrint$('entity_print', data, 'main', isSilent);
  }

  public entityPrint(
    entId: string,
    entType: string,
    stencilFieldKey: string,
    stencilId: string = null,
    isSilent = false
  ) {
    this.entityPrint$(entId, entType, stencilFieldKey, stencilId, isSilent).subscribe(({ blob, filename }) => {
      const fileUrl = window.URL.createObjectURL(blob);
      fileDownloaderFn(fileUrl, filename);
    });
  }

  public signPrint$(signIds: string[], isSilent = false): Observable<{ blob: Blob; filename: string; mime: string }> {
    const data = { sign_id: signIds };
    return this.actionPrint$('sign_print', data, 'main', isSilent);
  }

  public signPrint(signIds: string[], isSilent = false) {
    this.signPrint$(signIds, isSilent).subscribe(({ blob, filename }) => {
      const fileUrl = window.URL.createObjectURL(blob);
      fileDownloaderFn(fileUrl, filename, blob.type);
    });
  }

  public getBlobByUrl$(url: string): Observable<{ blob: Blob; mime: string }> {
    return of(null).pipe(
      mergeMap(() =>
        this.http.get(url, {
          observe: 'response',
          responseType: 'blob',
        })
      ),
      map((resp: HttpResponse<Blob>) => {
        const mime = resp.headers.get('content-type');
        return { blob: resp.body, mime };
      }),
      catchError((e) => {
        let message = `Не удалось загрузить файл`;
        if (e.error) {
          if (e.error.errors) {
            if (e.error.errors instanceof Array)
              message = e.error.errors.reduce(
                (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                ''
              );
            else if (e.error.errors.error)
              message =
                typeof e.error.errors.error === 'string' ? e.error.errors.error : JSON.stringify(e.error.errors.error);
          } else {
            message += (message ? '; ' : '') + e.error;
          }
        }
        return from(
          this.toast
            .create({
              header: 'Ошибка при обращении к серверу',
              message: `${++counter}. ${message}`,
              color: 'danger',
              position: 'top',
              buttons: [
                {
                  text: 'Ясно',
                  role: 'cancel',
                  handler: () => this.toastPresented$.next(false),
                },
              ],
            })
            .then((t) => {
              t.present();
              this.toastPresented$.next(true);
            })
            .then(() => {
              throw e;
            })
        );
      })
    );
  }

  public orgInfoDadataUpdateAct$(orgInfoId: string): Observable<any> {
    this.messageService.showLoading('Обновление данных...');
    return this.commonActRequest$({
      data: {
        context: 'main',
        action: 'org_info_dadata_update',
        data: { id: orgInfoId },
      },
    }).pipe(
      map((response) => response?.data ?? null),
      finalize(() => this.messageService.dismissLoading())
    );
  }

  // а можно переделать через commonEntRequest$ ?
  public fetchSummary$(): Observable<EntityModel> {
    this.messageService.showLoading();
    return this.http.get<ResponseModel>('/webapi/v1/summary/', { headers: HEADERS }).pipe(
      map((response) => {
        if (response.data) {
          let ent: any = response.data;
          ent.$isVnd = true;
          ent.$makeup = () => this.makeupEntity(ent);
          return ent;
        } else return null;
      }),
      finalize(() => this.messageService.dismissLoading())
    );
  }

  public fetchSchema$(entkey, isSilent = false): Observable<EntitySchemaModel> {
    return this.commonEntRequest$<EntitySchemaModel>(
      'schema',
      {
        entkey,
        caption: `Загрузка схемы`,
        captionErr: `Не удалось загрузить схему`,
      },
      isSilent
    );
  }

  public fetchMeta$(entkey, isSilent = true): Observable<EntityModel> {
    return this.commonEntRequest$<EntityModel>(
      'schema',
      {
        entkey,
        caption: `Загрузка метаданных`,
        captionErr: `Не удалось загрузить метаданные`,
      },
      isSilent
    );
  }

  public fetchOne$<T = EntityModel>(entkey, entid, isSilent = false, hasErrorToast = true): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        entid,
        // caption: `Загрузка данных`,
        captionErr: `Не удалось загрузить №&nbsp;${entid}`,
      },
      isSilent,
      hasErrorToast
    );
  }

  public create$<T = EntityModel>(entkey, srvData, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'post',
      {
        entkey,
        data: srvData,
        caption: `Создание..`,
        captionErr: `Не удалось создать`,
        captionSuccess: (ent) => `Создание выполнено, присвоен №&nbsp;${ent.id}`,
      },
      isSilent
    );
  }

  public update$<T = EntityModel>(entkey, entid, srvData, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'patch',
      {
        entkey,
        entid,
        data: srvData,
        caption: `Сохранение данных`,
        captionErr: `Не удалось сохранить №&nbsp;${entid}`,
        captionSuccess: `Сохранение выполнено`,
      },
      isSilent
    );
  }

  public deleteOne$<T = EntityModel>(entkey, entid, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'delete',
      {
        entkey,
        entid,
        caption: `Удаление данных`,
        captionErr: `Не удалось удалить №&nbsp;${entid}`,
        captionSuccess: `Удаление выполнено`,
      },
      isSilent
    );
  }

  public fetchAll$<T = EntityModel[]>(entkey, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        // caption: `Загрузка данных`,
        captionErr: `Не удалось загрузить`,
      },
      isSilent
    );
  }

  public fetchTop$<T = EntityModel[]>(entkey, limit: number, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        limit,
        // caption: `Загрузка данных`,
        captionErr: `Не удалось загрузить`,
      },
      isSilent
    );
  }

  public fetchPage$<T = EntityModel[]>(
    entkey,
    offset,
    limit,
    params: { [key: string]: string | string[] } = null,
    isSilent = false
  ): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        offset,
        limit,
        // caption: `Загрузка данных`,
        captionErr: `Не удалось загрузить`,
        params,
      },
      isSilent
    );
  }

  public fetchPortion$<T = EntityModel[]>(entkey, start, limit, isSilent = true): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        // caption: `Загрузка данных`,
        captionErr: `Не удалось загрузить`,
        start,
        limit,
      },
      isSilent
    );
  }

  public fetchQueried$<T = EntityModel[]>(
    entkey,
    query,
    params: { [key: string]: string | string[] } = null,
    isSilent = true,
    urlApi?
  ): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey,
        caption: `Загрузка выборки`,
        captionErr: `Не удалось загрузить выборку`,
        query,
        params,
        urlApi,
      },
      isSilent
    );
  }

  public getList$(listkey: string): Observable<any[]> | undefined {
    return __cash$[`all.${listkey}`];
  }

  public fetchDct$(
    dctkey: string,
    fetchAll = true,
    isSilent = false,
    params?: { [key: string]: any }
  ): Observable<any[]> {
    const o: any = {
      entkey: `dict/${dctkey}`,
      caption: `Загрузка словаря`,
      captionErr: `Не удалось загрузить словарь`,
    };
    if (fetchAll) o.limit = MAX_PAGE_SIZE;
    if (params) {
      o.params = { ...params };
    }
    return this.commonEntRequest$<any[]>('get', o, isSilent).pipe(
      map((list) => {
        __cash$[`all.${dctkey}`].next(list);
        __cash$[`dct.${dctkey}`].next(
          list.reduce((acc, item) => {
            acc[item.id] = item.$makeup().$snapshot;
            return acc;
          }, {})
        );
        return list;
      })
    );
  }

  public fetchOneDct$<T>(dctkey, itemId, isSilent = false): Observable<T> {
    return this.commonEntRequest$<T>(
      'get',
      {
        entkey: `dict/${dctkey}`,
        entid: itemId,
        caption: `Загрузка элемента словаря`,
        captionErr: `Не удалось загрузить элемент словаря`,
      },
      isSilent
    );
  }

  public fetchStaticDcts$(isSilent = false): Observable<boolean> {
    return this.commonEntRequest$<any[]>(
      'get',
      {
        entkey: 'dict/static',
        caption: `Загрузка статичных справочников`,
        captionErr: `загрузить статичные справочники`,
      },
      isSilent
    ).pipe(
      map((list) => {
        list.map((dict) => {
          if (__cash$[`dct.${dict.type}`]) {
            __cash$[`all.${dict.type}`].next(dict.items);
            __cash$[`dct.${dict.type}`].next(
              dict.items.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
              }, {})
            );
          } else {
            console.warn('[!] Незаявленный статичный словарь:', dict.type);
          }
        });
        return true;
      }),
      catchError(() => of(false))
    );
  }

  public fetchSomething$<T = EntityModel[]>(
    request: EntityRequestObjectModel,
    isSilent = false,
    hasErrorToast = true
  ): Observable<T> {
    request.captionErr = request.captionErr || 'Не удалось загрузить некоторые данные с сервера';
    request.entkey = request.endpoint || request.entkey;
    return this.commonEntRequest$<T>('get', request as any, isSilent, hasErrorToast);
  }

  public dismissAllToast() {
    if (this.toastPresented$.value) {
      this.toast.dismiss();
      this.toastPresented$.next(false);
    }
  }

  public fetchTask$(taskId: string, breakable = true): Observable<EntityModel> {
    return this.taskWatcher$(taskId, breakable).pipe(
      filter((ent) => ent.attributes.state && ent.attributes.state === 'done'),
      catchError((e) => {
        if (e === 'interrupt') return throwError(e);
        let message = e === 'interrupt' ? 'Выполнение задачи было прервано' : `Задача завершилась с ошибкой`;
        if (e.error?.errors) {
          if (e.error.errors instanceof Array)
            message = e.error.errors.reduce(
              (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
              ''
            );
          else if (e.error.errors.error)
            message =
              typeof e.error.errors.error === 'string' ? e.error.errors.error : JSON.stringify(e.error.errors.error);
        } else {
          message += (message ? '; ' : '') + e.error;
        }
        return from(
          this.toast
            .create({
              header: 'Ошибка при обращении к серверу',
              message,
              // duration: 10000,
              color: 'danger',
              position: 'top',
              buttons: [
                {
                  text: 'Ясно',
                  role: 'cancel',
                  handler: () => this.toastPresented$.next(false),
                },
              ],
            })
            .then((t) => {
              t.present();
              this.toastPresented$.next(true);
            })
            .then(() => {
              throw e;
            })
        );
      })
    );
  }

  public fetchReportTask$(reportId, isSilent = false): Observable<EntityModel> {
    if (!isSilent) {
      this.messageService.showLoading('Мониторинг выполнения задачи...');
    }
    return this.reportAct$(reportId, isSilent).pipe(
      switchMap((actTask) => this.fetchTask$(actTask.task_id)),
      finalize(() => !isSilent && this.messageService.dismissLoading())
    );
  }

  public analyticTaskAct$(
    o: {
      action: string;
      actData: any;
      message?: string;
    },
    isSilent
  ): Observable<IActTask> {
    if (!isSilent) {
      this.messageService.showLoading(o.message || 'Обработка...');
    }
    return this.commonActRequest$({
      data: {
        context: 'analytic',
        action: o.action,
        data: o.actData,
      },
      isSilent: false,
    }).pipe(
      map((response) => (response ? response.data : null)),
      finalize(() => !isSilent && this.messageService.dismissLoading())
    ) as Observable<IActTask>;
  }

  public commonRequestInterrupt$(entKey: string, entId: string): Observable<EntityModel> {
    return this.commonEntRequest$('patch', {
      // так как приватная
      entkey: entKey,
      entid: entId,
      data: {
        type: entKey,
        id: entId,
        attributes: { interrupt: true },
      },
    });
  }

  public fetchTrackingQueried$<T = EntityModel[]>(
    entkey,
    query,
    params: { [key: string]: string | string[] } = null,
    isSilent = false
  ): Observable<T> {
    return this.commonTrackingRequest$<T>({
      entkey,
      caption: `Загрузка выборки`,
      captionErr: `Сервис телеметрии в данное время недоступен, повторите запрос позже`,
      query,
      params,
      isSilent,
    });
  }

  public postTrackingAct$(barcode: string, isSilent = false): Observable<PostTrackingDataModel[]> {
    let data = { barcode };
    this.messageService.showLoading('Получение данных почтового отправления...');
    return this.commonActRequest$({
      data: {
        context: 'main',
        action: 'pochta_tracking',
        data,
      },
      isSilent,
      captionErr: 'Неверный ШПИ',
    }).pipe(
      map((response) => (response ? response.data : null)),
      finalize(() => this.messageService.dismissLoading())
    ) as Observable<PostTrackingDataModel[]>;
  }

  public nextInstanceVerificationAct$(entKey: string, error_message?: string): Observable<any> {
    this.messageService.showLoading('Получение следующего объекта валидации...');
    return this.commonActRequest$({
      data: {
        context: 'main',
        action: 'get_next_instance_verification',
        data: { type: entKey },
      },
    }).pipe(
      map((response) => {
        if (response.data.status && response.data.status === 'not_found') {
          let message = error_message ? error_message : `Нет в очереди`;
          return from(
            this.toast
              .create({
                message,
                color: 'warning',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present();
                this.toastPresented$.next(true);
              })
          );
        } else return response ? response.data : null;
      }),
      catchError((e) => {
        this.checkStatus(e);
        let message = e.message ? `${e.message}` : '';
        if (e.error) {
          if (e.error.errors) {
            if (e.error.errors instanceof Array) {
              message = e.error.errors.reduce(
                (acc, v) => `${acc ? acc + '<br/>' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                ''
              );
            } else {
              if (e.error.errors.error) {
                message =
                  typeof e.error.errors.error === 'string'
                    ? e.error.errors.error
                    : JSON.stringify(e.error.errors.error);
              }
            }
            throw new Error(message);
          }
        } else
          return from(
            this.toast
              .create({
                header: 'Ошибка при обращении к серверу',
                message: `${++counter}. ${message}`,
                color: 'danger',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present();
                this.toastPresented$.next(true);
              })
              .then(() => {
                throw e;
              })
          );
      }),
      finalize(() => this.messageService.dismissLoading())
    );
  }

  /**
   * Отправляет запрос на вывод средств
   *
   * @param data
   */
  public requestWithdrawal$(data: any): Observable<any> {
    return this.commonActRequest$({
      data: {
        context: 'payment',
        action: 'payment_action',
        data,
      },
    }).pipe(map((response) => response?.data ?? null));
  }

  /**
   * Запрашивает формирование счёта на пополнение
   *
   * @param data
   */
  public createDepositInvoice$(data: any): Observable<any> {
    return this.actionPrint$('print_payment_document', data, 'payment', true);
  }

  /** Преобразует строку во float */
  public strToFloat(object: any, schema: any, debug?: boolean) {
    Object.keys(object).forEach((key) => {
      const regex = new RegExp(key);
      const schemaKeys = Object.keys(schema).filter((k) => regex.test(k));
      // total_size_waste_fact - исключительное вычисляемое поле без схемы (schema)
      if (typeof object[key] === 'string' && schemaKeys.some((sk) => schema[sk] && schema[sk].type === 'float')) {
        object[key] = parseFloat(object[key]);
      }

      if (debug) {
        console.log(`${key} ${schemaKeys} ${object[key]}`);
      }
    });
  }

  // подумать над более абстрактным объединяющим неймингом
  private actionPrint$(
    action: string,
    data: any,
    context = 'main',
    isSilent = false
  ): Observable<{ blob: Blob; filename: string; mime: string }> {
    if (!isSilent) {
      this.messageService.showLoading('Загрузка печатной формы...');
    }

    return this.http
      .post(
        '/webapi/v1/action/',
        { context, action, data },
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .pipe(
        map((resp: HttpResponse<Blob>) => {
          const contentDisposition = resp.headers.get('content-disposition');
          const filename = contentDisposition.split('"')[1].trim();
          const mime = resp.headers.get('content-type');
          return { blob: resp.body, filename, mime };
        }),
        catchError((e) => {
          let message = `Не удалось загрузить печатную форму`;
          if (e.error) {
            if (e.error.errors) {
              if (e.error.errors instanceof Array)
                message = e.error.errors.reduce(
                  (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                  ''
                );
              else if (e.error.errors.error)
                message =
                  typeof e.error.errors.error === 'string'
                    ? e.error.errors.error
                    : JSON.stringify(e.error.errors.error);
            } else {
              message += (message ? '; ' : '') + e.error;
            }
          }
          return from(
            this.toast
              .create({
                header: 'Ошибка при обращении к серверу',
                message: `${++counter}. ${message}`,
                color: 'danger',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present();
                this.toastPresented$.next(true);
              })
              .then(() => {
                throw e;
              })
          );
        }),
        finalize(() => !isSilent && this.messageService.dismissLoading())
      );
  }

  private commonActRequest$(o: {
    data: any;
    message?: string;
    isSilent?: boolean;
    captionErr?: string;
  }): Observable<any> {
    if (!o.isSilent) {
      this.messageService.showLoading();
    }
    return this.http
      .post('/webapi/v1/action/', o.data, {
        headers: ACTION_HEADERS,
      })
      .pipe(
        catchError((e) => {
          this.checkStatus(e);
          let message = o.captionErr || '';
          if (e.error) {
            if (e.error.errors) {
              if (e.error.errors instanceof Array)
                message = e.error.errors.reduce(
                  (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                  ''
                );
              else if (e.error.errors.error)
                message =
                  typeof e.error.errors.error === 'string'
                    ? e.error.errors.error
                    : JSON.stringify(e.error.errors.error);
            } else {
              message += (message ? '; ' : '') + e.error;
            }
          }
          return from(
            this.toast
              .create({
                header: 'Ошибка при обращении к серверу',
                message: `${++counter}. ${message}`,
                color: 'danger',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present();
                this.toastPresented$.next(true);
              })
              .then(() => {
                throw e;
              })
          );
        })
      );
  }

  private commonEntRequest$<T = EntityModel>(
    method: MethodType,
    request: EntityRequestObjectModel,
    isSilent = true,
    hasErrorToast = true
  ): Observable<T> {
    if (!request.captionErr) {
      request.captionErr = 'Не удалось выполнить запрос';
    }
    const endpointKey = request.entkey;
    const urlApi = request.urlApi ? request.urlApi : 'webapi';
    let url = request.entid ? `/${urlApi}/v1/${endpointKey}/${request.entid}/` : `/${urlApi}/v1/${endpointKey}/`;

    const params = this.getRequestParams(request, method);

    return of(endpointKey).pipe(
      mergeMap(() => {
        switch (method) {
          case 'get':
            return this.http.get<ResponseModel>(url, {
              headers: HEADERS,
              params,
            });
          case 'put':
            return this.http.put<ResponseModel>(url, { data: request.data }, { headers: HEADERS });
          case 'post':
            return this.http.post<ResponseModel>(url, { data: request.data }, { headers: HEADERS });
          case 'patch':
            return this.http.patch<ResponseModel>(url, { data: request.data }, { headers: HEADERS });
          case 'delete':
            return this.http.delete<ResponseModel>(url, {
              headers: HEADERS,
            });
          case 'schema':
            return this.http.request('get', url, {
              headers: HEADERS,
              params,
            });
          default:
            console.warn(`An unknown server request method: ${method}`);
            return of(null);
        }
      }),
      map((response: ResponseModel) => {
        if (!response || !response.data) {
          return null;
        }

        let result: any | any[];
        let schema: any;

        if (response.data instanceof Array) {
          result = response.data.map((ent) => this.refineEnt(ent, request));
          if (response.meta?.schema) {
            schema = response.meta.schema;
            response.data.forEach((d) => this.strToFloat(d.attributes, schema));
          }
        } else {
          result = this.refineEnt(response.data, request);
          if (response.data.meta?.schema) {
            schema = response.data.meta.schema;
            this.strToFloat(response.data.attributes, schema);
          }
        }

        if (response.included) {
          if (schema) {
            response.included.forEach((i) => this.strToFloat(i.attributes, schema));
          }
          this.updateCash(response.included);
        }

        if (request.captionSuccess) {
          let successMsg =
            typeof request.captionSuccess === 'function' ? request.captionSuccess(result) : request.captionSuccess;

          if (!isSilent) {
            this.messageService.showLoading(successMsg, false);
            setTimeout(() => this.messageService.dismissLoading(), 1000);
          }
        }

        result.$meta = response.meta || response.data['meta'];

        return result;
      }),
      catchError((e) => {
        this.checkStatus(e);
        let message = request.captionErr;

        if (e.error) {
          if (e.error.errors) {
            if (e.error.errors instanceof Array)
              message = e.error.errors.reduce(
                (acc, v) => `${acc ? acc + '<br/>' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                ''
              );
            else if (e.error.errors.error)
              message =
                typeof e.error.errors.error === 'string' ? e.error.errors.error : JSON.stringify(e.error.errors.error);
          }
        }

        if (!hasErrorToast) {
          return throwError(e);
        } else {
          return from(
            this.toast
              .create({
                header: 'Ошибка при обращении к серверу',
                message: `${++counter}. ${message}`,
                color: 'danger',
                position: 'top',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                    handler: () => this.toastPresented$.next(false),
                  },
                ],
              })
              .then((t) => {
                t.present().then(() => this.messageService.dismissLoading());
                this.toastPresented$.next(true);
              })
              .then(() => {
                throw e;
              })
          );
        }
      })
    );
  }

  /*
   * Возвращает параметры из объекта request
   *
   * @param request - полный список параметров запроса
   * @param method - метод
   */
  private getRequestParams(request: EntityRequestObjectModel, method?: MethodType) {
    const params = request.params || {};

    if (request.offset) {
      params['page[offset]'] = request.offset.toString();
    }
    if (request.limit) {
      params['page[limit]'] = method && method === 'schema' ? '0' : request.limit.toString();
    }
    if (request.start) {
      params['start'] = request.start.toString();
    }
    if (request.query) {
      params['query'] = request.query.toString();
    }
    if (request.search) {
      params['search'] = request.search.toString();
    }

    return new HttpParams({ fromObject: params });
  }

  private refineEnt(ent, o) {
    ent.$isVnd = true;
    ent.$type = ent.type || o.entkey;
    ent.$makeup = () => this.makeupEntity(ent);
    return ent;
  }

  private updateCash(items) {
    if (items)
      items.forEach((item) => {
        __cash[`${item.type}#${item.id}`] = item;
      });
  }

  private makeupEntity(ent: EntityModel): EntityModel {
    if (ent.$snapshot) return ent;
    else ent.$snapshot = { id: ent.id, ...ent.attributes, ...ent.meta };

    if (ent.relationships) {
      Object.keys(ent.relationships).forEach((propKey) => {
        let propData = ent.relationships[propKey].data;
        if (propData instanceof Array) propData = propData.map((item) => this.makeupItem(item));
        else if (propData) propData = this.makeupItem(propData);
        ent.$snapshot[propKey] = propData;
      });
    }
    if (ent.meta) {
      if (ent.meta.allow_actions) {
        ent.$actions = ent.meta.allow_actions;
        ent.$canView = !!~ent.$actions.indexOf('view');
        ent.$canEdit = !!~ent.$actions.indexOf('change');
        ent.$canRemove = !!~ent.$actions.indexOf('delete');
      }
      if (ent.meta.allow_transits) ent.$transits = ent.meta.allow_transits;
      if (ent.meta.allow_transit_groups) ent.$transit_groups = ent.meta.allow_transit_groups;
      if (ent.meta.versions) {
        ent.$versions = ent.meta.versions;
        ent.$versions.forEach((version) => {
          if (version.is_active) {
            version.$isActive = true;
          }
          if (version.id === ent.id) {
            version.$isCurrent = true;
            version.$isOpened = true;
          }
        });
      }
      if (ent.meta.signatures) ent.$signatures = ent.meta.signatures;
    }
    if (ent.attributes && ent.attributes.is_active !== undefined)
      ent.$role = ent.attributes.is_active ? 'active' : 'archived';

    return ent;
  }

  private makeupItem(item) {
    let relatedEnt = __cash[`${item.type}#${item.id}`];
    let madeupEnt = relatedEnt
      ? {
          ...relatedEnt,
          $makeup: () => this.makeupEntity(madeupEnt),
          $isVnd: true,
        }
      : { ...item, $isRelation: true };

    return madeupEnt;
  }

  private taskWatcher$(taskId: string, breakable = true): Observable<EntityModel> {
    return new Observable<EntityModel>((sub) => {
      const maxAttempts = 130;
      let curAttempt = 0;
      let interval = 3000; // startInterval;
      let watcherSub: Subscription;

      breakable &&
        this.toast
          .create({
            header: `Прервать выполнение задачи`,
            // duration: 10000,
            color: 'primary',
            position: 'top',
            buttons: [
              {
                text: 'Прервать',
                handler: () => {
                  this.commonRequestInterrupt$('task', taskId).subscribe(() => console.log('[DEV] Задача прервана'));
                  this.toastPresented$.next(false);
                },
              },
              {
                text: 'Скрыть',
                role: 'cancel',
                handler: () => this.toastPresented$.next(false),
              },
            ],
          })
          .then((t) => {
            t.present();
            this.toastPresented$.next(true);
          });

      const tsk = () => {
        watcherSub = timer(interval)
          .pipe(switchMap(() => this.fetchOne$('task', taskId, true)))
          .subscribe((task) => {
            if (++curAttempt > maxAttempts) {
              return sub.error('Превышено чисто попыток обращения к статусу задачи');
            }
            if (task.attributes?.interrupt) return sub.error('interrupt');

            switch (task.attributes.state) {
              case 'done':
                sub.next(task);
                this.dismissAllToast();
                return sub.complete();

              case 'running':
              case 'starting':
              case 'waiting':
                sub.next(task);
                return tsk();

              default:
                return sub.error(task);
            }
          });
      };
      // Run task in First Time
      tsk();

      return () => watcherSub.unsubscribe();
    });
  }

  private reportAct$(reportId: string, isSilent): Observable<IActTask> {
    let data = { report_id: reportId };
    return this.analyticTaskAct$(
      {
        action: 'report_build_xlsx',
        actData: data,
        message: 'Формирование отчета',
      },
      isSilent
    );
  }

  private commonTrackingRequest$<T = any>(request: EntityRequestObjectModel): Observable<any> {
    const isSilent = request.isSilent ?? true;
    if (!request.caption) request.caption = 'Обмен данными с сервером';
    if (!request.captionErr) request.captionErr = 'Не удалось выполнить запрос';
    let url = request.entid
      ? `/webapi/v1/tracking/${request.entkey}/${request.entid}/`
      : `/webapi/v1/tracking/${request.entkey}/`;

    const params = this.getRequestParams(request);

    if (!isSilent && request.message) {
      this.messageService.showLoading(request.message);
    }

    return this.http.get<any>(url, { headers: ACTION_HEADERS, params }).pipe(
      catchError((e) => {
        this.checkStatus(e);
        let message = request.captionErr || '';
        return from(
          this.toast
            .create({
              header: 'Ошибка при обращении к серверу',
              message: `${++counter}. ${message}`,
              color: 'danger',
              position: 'top',
              buttons: [
                {
                  text: 'Ясно',
                  role: 'cancel',
                  handler: () => this.toastPresented$.next(false),
                },
              ],
            })
            .then((t) => {
              t.present();
              this.toastPresented$.next(true);
            })
            .then(() => {
              throw e;
            })
        );
      }),
      finalize(() => !isSilent && this.messageService.dismissLoading())
    );
  }

  private checkStatus(e) {
    if (e.status === '401')
      this.toast
        .create({
          header: 'Возможно ваша сессия устарела.',
          message: 'Обновите страницу, или пройдите авторизацию ещё раз.',
          color: 'warning',
          position: 'top',
          buttons: [
            {
              text: 'Хорошо',
              role: 'cancel',
              handler: () => location.reload(),
            },
          ],
        })
        .then((t) => t.present());
  }
}
