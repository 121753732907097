import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule } from '@angular/forms';
import { TermsModule } from './terms/terms.module';
import { DctModule } from './dct/dct.module';
import { UnauthorizedPage } from './unauthorized/unauthorized.page';
import { UnderconstructionPageModule } from './underconstruction/underconstruction.module';
import { ConfigurationService } from './configuration.service';
import { TermPipe } from './terms/term.pipe';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu, 'ru');

const loadConfiguration = (configService: ConfigurationService) => () => configService.loadConfiguration();

@NgModule({
  declarations: [AppComponent, UnauthorizedPage],
  imports: [
    BrowserModule,
    TermsModule.forRoot(),
    DctModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    UnderconstructionPageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      deps: [ConfigurationService],
      multi: true,
    },
    Title,
    TermPipe,
    { provide: LOCALE_ID, useValue: 'ru' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
