import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private showLoading$: Subject<string> = new Subject<string>();
  private dismissLoading$: Subject<void> = new Subject<void>();

  private readonly loadingOptions: LoadingOptions = {
    message: 'Загрузка данных...',
    cssClass: 'nci-loading',
    spinner: 'dots',
  };

  private loadingInstance: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {
    this.createLoading();

    // Показываем лоадинг по подписке
    this.showLoading$.subscribe((message?: string) => {
      this.loadingInstance?.present().then(() => {
        if (message) {
          this.loadingInstance.message = message;
        }
      });
    });

    // Скрываем лоадинг по подписке
    this.dismissLoading$.subscribe(() => {
      this.loadingInstance?.dismiss();
    });
  }

  public showLoading(message?: string, spinner?: boolean): void {
    spinner = spinner ?? true;
    if (this.loadingInstance && !spinner) {
      this.loadingInstance.spinner = null;
    }
    this.showLoading$.next(message);
  }

  public dismissLoading(): void {
    this.dismissLoading$.next();
  }

  private createLoading(): void {
    this.loadingController.create(this.loadingOptions).then((loading) => {
      this.loadingInstance = loading;
      this.loadingInstance.onDidDismiss().then(() => this.createLoading());
    });
  }
}
