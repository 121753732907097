export const fileDownloaderFn = (fileUrl: string, fileName: string, mimeType?: string) => {
  // if (DebugMode.isOn) console.log('[DEBUG]', fileUrl, fileName)
  const element = document.createElement('a');
  element.setAttribute('href', fileUrl);
  if (mimeType && (mimeType === 'application/pdf' || mimeType === 'application/zip'))
    element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const copyMessage = (val: string) => {
  window.navigator.clipboard.writeText(val);
};
