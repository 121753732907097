import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  public getObservable<T>(url: string, options?: any): Observable<T> {
    return <Observable<T>>this.http.get<T>(url, options).pipe(map((i: any) => i as T));
  }

  public postObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.post<T>(url, data, options).pipe(map((i: any) => i as T));
  }

  public deleteObservable<T>(url: string, options?: any): Observable<T> {
    return <Observable<T>>this.http.delete<T>(url, options).pipe(map((i: any) => i as T));
  }

  public patchObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.patch(url, data, options).pipe(map((i: any) => i as T));
  }

  public putObservable<T>(url: string, data?: any, options?: any): Observable<T> {
    return <Observable<T>>this.http.put<T>(url, data, options).pipe(map((i: any) => i as T));
  }

  /** Конвертирует примитивные свойства объекта в параметры запроса */
  public getRequestParams(data: any): HttpParams {
    let requestParams = new HttpParams();
    for (const option in data) {
      if (data.hasOwnProperty(option)) {
        let optionValue = data[option];

        if (optionValue !== null && optionValue !== undefined) {
          optionValue = encodeURIComponent(optionValue);
          requestParams = requestParams.append(option, optionValue);
        }
      }
    }
    return requestParams;
  }
}
