import { environment } from '../environments/environment';

export const baseUrl = environment.baseUrl;

/** Урлы */
export class Urls {
  public static getApplicationContractsUrl(): string {
    return `${baseUrl}/contract/`;
  }

  public static getWasteHandlingPriceUrl(): string {
    return `${baseUrl}/fkko_tariff/`;
  }

  public static getOrderRecyclingActUrl(actId: number): string {
    return `${baseUrl}/document_act_provide_order_recycling/${actId}/`;
  }

  public static getOrderTransportationActUrl(actId: number): string {
    return `${baseUrl}/document_act_provide_order_transportation/${actId}/`;
  }

  public static getMakeNotificationReadByUuidUrl(uuid: string): string {
    return `${baseUrl}/notice/${uuid}/read/`;
  }

  public static getAvailableWasteTypesUrl(): string {
    return `${baseUrl}/dict/fkko/`;
  }

  public static getVehiclesListUrl(): string {
    return `${baseUrl}/vehicle/`;
  }

  public static getWasteTypesForIndividualsUrl(): string {
    return `${baseUrl}/dict/fkko/`;
  }

  public static getSelectedTransportationOfferForIndividualOrderUrl(offerId: number): string {
    return `${baseUrl}/individual_transportation_offer/${offerId}/`;
  }

  public static getActionUrl(): string {
    return `${baseUrl}/action/`;
  }
}
