import { Inject, Injectable } from '@angular/core';
import { TermService } from '../terms/term.service';
import { EntityModel } from '@appModels/entity-model';
import { AuthEsiaModel } from '@appModels/auth-esia-model';
import { TokenIntegrationModel } from '@appModels/token-integration-model';
import { AuthOrgModel } from '@appModels/auth-org-model';
import { RoleModel } from '@appModels/role-model';
import { OrganizationModel } from '@appModels/organization-model';
import { UserModel } from '@appModels/user-model';
import { UserRoleModel } from '@appModels/user-role-model';
import { HttpClient } from '@angular/common/http';
import { NavController, ToastController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { SrvService } from '@appServices/srv.service';
import { BehaviorSubject, from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ListPaginatorComponent } from '../ws/list-component/list-paginator.component';
import { ConfigurationService } from '../configuration.service';
import { COMMON_OPTIONS, LAYERS, TILE_SERVER_DEFAULT } from '../const-dev';
import { DebugMode } from '@appServices/debug.service';
import { MessageService } from '@appServices/message.service';
import { MoneyInfoModel } from '@appModels/money-info-model';

export interface IAppLayer {
  form?: FormGroup;
  entity?: EntityModel;
  modal?: any;
  type?: 'entity' | 'list' | 'preview';
  label?: string;
  icon?: string;
  selected?: boolean;
  paginator?: ListPaginatorComponent;
  focused?: boolean;
}

const UI_SCOPE = {
  'ui.page.databoard.profile': true,
  'ui.page.databoard.profile.organization': true,
  'ui.page.databoard.selected_organization': true,
  'ui.page.databoard.licenses': true,
  'ui.page.databoard.self_licenses': true,
  'ui.page.databoard.wasteplaces': true,
  'ui.page.databoard.facilities': true,
  'ui.page.databoard.vehicles': true,

  'ui.page.summary': true,
  'ui.page.summary.bystatuses.wastereport_operation': true,
  'ui.page.summary.bystatuses.wasteplace': true,
  'ui.page.summary.bystatuses.vehicle': true,
  'ui.page.summary.bystatuses.organization_info': true,
  'ui.page.summary.bystatuses.facility': true,
  'ui.page.summary.bystatuses.bid': true,
  'ui.page.summary.total.user': true,

  'ui.page.analytics': true,
  'ui.page.organizations': true,
  'ui.page.licenses': true,
  'ui.page.wasteplaces': true,
  'ui.page.wasteplaces.has_next_instance_verification': true,
  'ui.page.vehicles': true,
  'ui.page.facilities': true,
  'ui.page.facilities.has_next_instance_verification': true, // 'ui.page.modeling': true,
  // 'ui.page.telemetry': true,
  'ui.page.contract-offers': true,
  'ui.page.contract-applications': true,
  'ui.page.contracts': true,
  'ui.page.lots-transportation': true,
  'ui.page.contracts-owner': true,
  'ui.page.transportation-offers': true,
  'ui.page.orders': true,
  'ui.page.individual-lots-transportation': true,
  'ui.page.individual-orders': true,
  'ui.page.individual-orders-owner': true,
  'ui.page.individual-transportation-offers': true,
  'ui.page.dumpsters': true,

  // возможность создания сущности
  'ui.ent.wasteplace.can_create': true,
  'ui.ent.vehicle.can_create': true,
  'ui.ent.facility.can_create': true,
  'ui.ent.contract_offer.can_create': true,
  'ui.ent.contract_application.can_create': true,
  'ui.ent.transportation_offer.can_create': true,
  'ui.ent.order.can_create': true,
  'ui.ent.individual_order.can_create': true,

  // клиент
  'ui.client.workspace-wa': true,
  'ui.client.agent-ma': false,
};
const UI_TERMS = [
  {
    slug: 'ui.page.databoard.profile.title',
    type: 'string',
  },
  {
    slug: 'ui.page.databoard.self_licenses.title',
    type: 'string',
  },
  {
    slug: 'ui.page.databoard.licenses.title',
    type: 'string',
  },
  {
    slug: 'ui.page.databoard.wasteplaces.title',
    type: 'string',
  },
  {
    slug: 'ui.page.databoard.facilities.title',
    type: 'string',
  },
  {
    slug: 'ui.page.databoard.vehicles.title',
    type: 'string',
  },
  {
    slug: 'ui.page.wasteplaces.title',
    type: 'string',
  },
  {
    slug: 'ui.page.wasteplaces.hides',
    type: 'enum',
  },
  {
    slug: 'ui.page.facilities.title',
    type: 'string',
  },
  {
    slug: 'ui.page.facilities.hides',
    type: 'enum',
  },
  {
    slug: 'ui.page.vehicles.title',
    type: 'string',
  },
  {
    slug: 'ui.page.vehicles.hides',
    type: 'enum',
  },
  {
    slug: 'ui.page.licenses.title',
    type: 'string',
  },
  {
    slug: 'ui.page.licenses.hides',
    type: 'enum',
  },
  {
    slug: 'ui.page.summary.bystatuses.wastereport_operation.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.bystatuses.wasteplace.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.bystatuses.vehicle.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.bystatuses.organization_info.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.bystatuses.facility.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.bystatuses.bid.title',
    type: 'string',
  },
  {
    slug: 'ui.page.summary.total.user.title',
    type: 'string',
  },
  {
    slug: 'ui.page.analytics.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.analytics.title',
    type: 'string',
  },
  {
    slug: 'ui.page.datafiles.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.contract-offers.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.contract-offers.title',
    type: 'string',
  },
  {
    slug: 'ui.page.contract-applications.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.contract-applications.title',
    type: 'string',
  },
  {
    slug: 'ui.page.contract.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.contract.title',
    type: 'string',
  },
  {
    slug: 'ui.page.transportation_offer.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.transportation_offer.title',
    type: 'string',
  },
  {
    slug: 'ui.page.orders.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.orders.title',
    type: 'string',
  },
  {
    slug: 'ui.page.dumpsters.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.dumpsters.title',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-lots-transportation.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-lots-transportation.title',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-orders.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-orders.title',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-orders-owner.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-orders-owner.title',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-transportation-offers.icon',
    type: 'string',
  },
  {
    slug: 'ui.page.individual-transportation-offers.title',
    type: 'string',
  },
];

const UI_VISUALS = {
  '#1': {
    hasAva: false,
    sideMenu: 'some',
    menuTheme: 'dark-theme',
  },
  '#2': {
    hasAva: false,
  },
  '#ossig': {
    asideWidth: '340px',
    hasSideBarHeader: true,
    hasSideMenu: true,
    hasSideBarBlock_roles: true,
    hasSideBarElement_ava: true,
    hasSideBarElement_menuLines: true,
    hasSideBarElement_menuIcons: true,
    hasUIElement_frames: true,
    testMode: true,
  },
  '#eco': {
    hasAva: false,
    sideMenu: 'some',
    menuTheme: 'dark-theme-ext',
    hasSideMenu: true,
    uiTheme: 'eco-theme',
    hasPermanentHeader: true,
    hasDominantColor: true,
    hasPermanentHeaderBlock_roles: true,
    paginatorKind: 'buttons',
    headerKind: 'noTitleAndSimpleButtons',
  },
  '#hard': {
    hasAva: false,
    menuTheme: 'dark-theme-ext',
    uiTheme: 'hard-theme',
    hasSideMenu: true,
    hasSideBarBlock_roles: true,
    headerKind: 'noTitleAndSimpleButtons',
    hasUIElement_frames: true,
    hasRO_role: true,
  },
};

const UI_ENTRANCE = {
  '#eco': {
    tabTitle: 'АИС «ОССиГ»',
    authBlockInCenter: true,
    shortSystemName: 'ОССиГ',
    systemName: 'Система обращения с отходами строительства, сноса и грунтов',
    email: 'support@ossig.ru',
    phone: '+7 (495) 456 34 53',
    background: '#7F94B9',
  },
  '#hard': {
    tabTitle: 'АИС «ОССиГ»',
    authBlockInCenter: true,
    shortSystemName: 'ОССиГ',
    systemName: 'Система обращения с отходами строительства, сноса и грунтов',
    email: 'support@ossig.ru',
    phone: '+7 (495) 456 34 53',
    background: '#665751',
    headerTitle: 'Обращение с отходами <br> строительства, <br> сноса и грунтов',
    hasLogoB3: true,
  },
};

const UI_CLIENT = {
  'ui.client.workspace-wa': true,
  'ui.client.agent-ma': false,
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  visualCode: string = '#ossig';
  visualStyle$: BehaviorSubject<any> = new BehaviorSubject(this.visualStyle);
  public visualEntranceStyle$: BehaviorSubject<any> = new BehaviorSubject(this.visualEntranceStyle);
  isModalView = false;
  uiIsDark = false;
  defaultWsPage = '/ws/orders';
  profile = null;
  public currentRole = null;
  token_integration: TokenIntegrationModel = null;
  user = null;
  roles = null;
  role: RoleModel = null;
  licenses: any[] = null;
  organization = null;
  mainPagesList = null;
  mainErrorMessage = null;
  auth: AuthEsiaModel = null;
  authFollowUrl: string = null;
  trigger$: Subject<string> = new Subject();
  //TODO: На первый взгляд кажется, что Субъекта вполне достаточно
  layersSubject = new BehaviorSubject<IAppLayer[]>([]);
  isMapShown: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private LAYERS = {
    OPENSTREET: {
      key: 'OPENSTREET',
      title: '<span class="icon icon_map2"></span> OpenStreetMap',
      url: `https://{s}.${this.tileServer}/street/{z}/{x}/{y}.png`,
      options: COMMON_OPTIONS,
    },
    BLACK_WHITE: {
      key: 'BLACK_WHITE',
      title: '<span class="asuicon asuicon_blackandwhite"></span> Черно-белая OpenStreetMap',
      url: `https://{s}.${this.tileServer}/bw/{z}/{x}/{y}.png`,
      options: COMMON_OPTIONS,
    },
    VOLGA: {
      key: 'VOLGA',
      url: 'https://map.b3asu.ru/volga/{z}/{x}/{y}.png',
      title: '<span class="icon icon_panorama"></span> Берега Волги',
      options: {
        minNativeZoom: 8,
        maxNativeZoom: 21,
        maxZoom: 21,
        attribution: '&copy; <a href="">Volga</a>',
      },
    },
  };

  public LAYERS_SETS = {
    entry: { default: LAYERS.BLACK_WHITE },
    modal: {
      default: this.LAYERS.OPENSTREET,
      twoGIS: LAYERS.TWOGIS,
    },
    back: {
      default: this.LAYERS.OPENSTREET,
      openStreetMapBlackAndWhite: this.LAYERS.BLACK_WHITE,
      satellite: LAYERS.SATELLITE,
      twoGIS: LAYERS.TWOGIS,
      light: LAYERS.LIGHT,
      dark: LAYERS.DARK,
      toner: LAYERS.TONER,
      traffic: LAYERS.TRAFFIC,
    },
    overlay: {
      grid_2: LAYERS.GRID2,
      grid_10: LAYERS.GRID10,
      volga: LAYERS.VOLGA,
      cadastre: LAYERS.CADASTRE,
      gps: LAYERS.GPS,
    },
    common: {},
  };
  private _currentRoleCode: string = '';

  constructor(
    private termSe: TermService,
    private http: HttpClient,
    private nav: NavController,
    private toast: ToastController,
    private srv: SrvService,
    private router: Router,
    @Inject(DOCUMENT) readonly document: Document,
    private appConfig: ConfigurationService,
    private messageService: MessageService
  ) {
    // @ts-ignore
    if (window) window.state = this;

    this.srv.getTerm = this.termSe.getTerm.bind(this.termSe);
  }

  get visualStyle(): any {
    return this.appConfig.visualStyle || UI_VISUALS[this.visualCode];
  }

  get visualEntranceStyle(): any {
    return this.appConfig.visualEntranceStyle || UI_ENTRANCE[this.visualCode];
  }

  get visualEntranceStyles(): string[] {
    return Object.keys(UI_VISUALS);
  }

  get tileServer(): any {
    return this.appConfig.tileServer && this.appConfig.tileServer !== TILE_SERVER_DEFAULT
      ? this.appConfig.tileServer
      : TILE_SERVER_DEFAULT;
  }

  get currentRoleCode(): string {
    let now = new Date().getTime();
    let currentRoleCode = this._currentRoleCode || '';
    if (localStorage.getItem('role')) {
      let ls = JSON.parse(localStorage.getItem('role'));
      return now - Number(ls.timestamp) < 86400000 ? ls.value : currentRoleCode;
    } else return currentRoleCode;
  }

  get window(): Window {
    return this.document.defaultView;
  }

  get currentRoleName(): string {
    return (this.currentRole && this.currentRole.name) || 'Без роли';
  }

  setVisualEntranceStyle(code: string) {
    this.visualCode = code;
    this.visualEntranceStyle$.next(this.visualEntranceStyle);
  }

  toggleMap() {
    this.isMapShown.next(!this.isMapShown.value);
  }

  triggerEvent(actionKey: string | string[]) {
    if (actionKey instanceof Array) {
      actionKey.forEach((key) => this.trigger$.next(key));
    } else {
      this.trigger$.next(actionKey);
    }
  }

  onTriggeredEvent$(keys: Set<string>, bounceTime: number = 500): Observable<any> {
    return this.trigger$.pipe(
      filter((key) => keys.has(key)),
      debounceTime(bounceTime)
    );
  }

  public selectOrg(org: AuthOrgModel) {
    if (!this.auth || !this.auth.data) return;

    if (this.auth.data.state === 'wait') {
      this.redirect(this.auth.data.url_follow.replace('/*/', `/${org.oid}/`));
    }
  }

  makeProfile(user: UserModel, org: OrganizationModel, roles: UserRoleModel[]) {
    let profile = {
      name: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.username,
      orgname:
        user._organization && user._organization.__str__ ? user._organization.__str__ : org ? org.name_short : null,
      organizationInfoId: user.organization_info_id,
      organizationRootId: user.organization ? user.organization.id : null,
      profilePic: 'assets/img/default-avatar.png',
      id: user.id,
      mainRole: 'full',
      vars: {
        'user.modeling_host': user.modeling_host,
        'user.telemetry_host': user.telemetry_host,
        'user.session_id': user.session_id,
      },
      orgGeoPoint: user._organization && user._organization.point ? user._organization.point : null,
      user_profile: user.profile,
      _vicarious: user._vicarious,
      _payment_account: user._payment_account,
    };
    this.profile = profile;
    this.roles = roles;
    this.organization = org || {};
    this.remakeProfile();
  }

  remakeProfile() {
    this.profile.roles = [];
    this.roles.forEach((srvRole) => {
      this.profile.roles.push({
        code: `role#${srvRole.id}`,
        isMain: srvRole.is_default,
        ...srvRole,
      });
    });
    // console.log('[PROFILE]', this.profile);
    const mainRole = this.profile.roles.find(
      (role) =>
        (role.isMain && this.hasUiItem('ui.client.workspace-wa', 'client', role)) ||
        this.hasUiItem('ui.client.workspace-wa', 'client', role)
    );

    const roleCode = this.profile.roles.find(
      (role) => role.code === this.currentRoleCode && this.hasUiItem('ui.client.workspace-wa', 'client', role)
    )
      ? this.currentRoleCode
      : (mainRole && mainRole.code) || this.profile.mainRole || this.profile.roles[0].code;
    if (roleCode) this.adjustRole(roleCode);
  }

  adjustRole(roleCode, thenCheckDefaultPage = false) {
    let role = this.profile.roles.find((role) => role.code === roleCode);

    this.currentRole = role;
    this.mainPagesList = [];
    this.termSe.currentOverride = role.override;
    role.scope
      .filter((rule) => rule && rule.indexOf('ui.page.') === 0 && rule.split('.').length === 3)
      .forEach((pageRule) => {
        let pageCode = pageRule.slice(8);
        let externalLink = this.termSe.getTerm(`ui.page.${pageCode}.externalLinkUri`);
        let filterPresets: string | string[] = this.termSe.getTerm(`ui.page.${pageCode}.filter_presets`);
        if (typeof filterPresets === 'string') filterPresets = filterPresets.split(',');
        let menuItem: any & {
          subPagesSbscrptn?: Subscription;
        } = {
          title: this.termSe.getTerm(`ui.page.${pageCode}.title`) || pageCode,
          url: externalLink || `/ws/${pageCode}`,
          icon: this.termSe.getTerm(`ui.page.${pageCode}.icon`) || 'apps',
          isExternalLink: !!externalLink,
          externalLinkTarget: '_blank' || this.termSe.getTerm(`ui.page.${pageCode}.externalLinkTarget`),
        };
        if (filterPresets && filterPresets instanceof Array) {
          menuItem._isOpenedInSidemenu = false;
          menuItem.subPages = filterPresets.map((filterPresetCode) => {
            let title: string = filterPresetCode;
            return {
              type: 'filter',
              code: filterPresetCode,
              title,
              filter: {},
              goto: (() => {
                this.router.navigate([menuItem.url], {
                  queryParams: {
                    filterPresetCode,
                  },
                  queryParamsHandling: 'merge',
                  replaceUrl: true,
                });
              }).bind(this),
            };
          });
          menuItem.refreshSidemenuStats = () => {
            if (menuItem.subPagesSbscrptn) menuItem.subPagesSbscrptn.unsubscribe();
            menuItem.subPagesSbscrptn = this.srv
              .fetchSomething$({
                endpoint: 'filter_preset_aggs',
                params: {
                  _filter_preset: filterPresets,
                },
              })
              .pipe(take(1))
              .subscribe((data) => {
                // console.log('[DEV] fetch presets', data);
                menuItem.subPagesSbscrptn = null;
                menuItem.subPages
                  .filter((p) => p.type === 'filter')
                  .forEach((subPage) => {
                    subPage.count = data[subPage.code];
                  });
              });
          };
          Object.defineProperty(menuItem, 'isOpenedInSidemenu', {
            get: () => menuItem._isOpenedInSidemenu,
            set: (isOpenedInSidemenu) => {
              console.log('SET isOpenedInSidemenu', pageCode, isOpenedInSidemenu);
              menuItem._isOpenedInSidemenu = isOpenedInSidemenu;
              if (isOpenedInSidemenu) menuItem.refreshSidemenuStats();
            },
          });
        }
        this.mainPagesList.push(menuItem);
      });
    if (DebugMode.isOn) console.log('Main pages', this.mainPagesList, role.mainPage);
    if (this._currentRoleCode !== roleCode) {
      let ls = { value: roleCode, timestamp: new Date().getTime() };
      localStorage.setItem('role', JSON.stringify(ls));
      this._currentRoleCode = roleCode;
    }

    this.defaultWsPage = role.mainPage;

    setTimeout(() => {
      this.triggerEvent('role.init');
      if (thenCheckDefaultPage) this.gotoDefaultPageIfChanged();
    }, 0);
  }

  gotoDefaultPageIfChanged() {
    if (DebugMode.isOn) console.log('[DEV] try to go to default', this.defaultWsPage);
    if (this.mainPagesList.every((item) => item.url !== this.router.url.slice(0, item.url.length))) {
      this.nav.navigateForward([this.defaultWsPage]);
    }
  }

  getDataRole(role): Observable<any> {
    return this.srv.fetchOne$('role_ui', role.id).pipe(
      map((_role) => {
        const profileVars = {
          'user.modeling_host': this.user.modeling_host,
          'user.telemetry_host': this.user.telemetry_host,
          'user.session_id': this.user.session_id,
        };
        const terms = _role.$makeup().$snapshot.permissions.map((term) => {
          if (term.value && typeof term.value === 'string')
            term.value = term.value.replace(/\{([^\{\}]*)\}/g, (m, varCode) =>
              profileVars[varCode] !== undefined ? `${profileVars[varCode]}` : `UNDEFINED_${varCode}`
            );
          return term;
        });

        const termsDct = terms.reduce((acc, item) => {
          acc[item.slug] = item.value;
          return acc;
        }, {});

        const uiRole = {
          ...role,
          mainPage: termsDct['mainPage'],
          scope: terms.filter((term) => UI_SCOPE[term.slug]).map((term) => term.slug),
          override: {
            ...Object.keys(termsDct)
              .filter(
                (k) =>
                  k.substring(0, 8) === 'ui.page.' || k.substring(0, 7) === 'ui.ent.' || ~k.indexOf('filter_preset')
              )
              .reduce((acc, termSlug) => {
                acc[termSlug] = termsDct[termSlug];
                return acc;
              }, {}),
            ...UI_TERMS.reduce((acc, term) => {
              if (termsDct[term.slug]) acc[term.slug] = termsDct[term.slug];
              return acc;
            }, {}),
          },
          client: terms.filter((term) => UI_CLIENT[term.slug]).map((term) => term.slug),
        };
        return uiRole;
      })
    );
  }

  clearFilterPreset() {
    this.router.navigate([], {
      queryParams: {
        filterPresetCode: undefined,
      },
      queryParamsHandling: 'merge',
    });
  }

  hasSectionItem(sectionCode, itemCode) {
    if (!this.currentRole) return true;
    if (!this.currentRole.override) return true;
    if (!this.currentRole.override[`ui.${sectionCode}.hides`]) return true;
    return !~this.currentRole.override[`ui.${sectionCode}.hides`].indexOf(itemCode);
  }

  hasScopeItem(scopeItemCode: string) {
    return !!this.currentRole?.scope?.includes(scopeItemCode);
  }

  hasUiItem(itemCode, groupKey, role = this.currentRole) {
    return !!(role && role[groupKey] && role[groupKey].includes(itemCode));
  }

  public gotoEntrance() {
    this.nav.navigateRoot('/entrance');
  }

  public navto(path) {
    this.nav.navigateRoot(path);
  }

  public noteAuthError(err) {
    this.mainErrorMessage = `${err.text || err.code || err}`;
    this.toast
      .create({
        header: 'Доступ запрещен',
        message: this.mainErrorMessage,
        color: 'danger',
        position: 'middle',
        buttons: [
          {
            text: 'Ясно',
            role: 'cancel',
            handler: () => this.srv.toastPresented$.next(false),
          },
        ],
      })
      .then((t) => {
        t.present();
        this.srv.toastPresented$.next(true);
      });
  }

  public noteAuthSuccess() {
    this.messageService.showLoading('Первичный доступ предоставлен');
  }

  public noteRoleMobileClient(nameRole: string) {
    this.toast
      .create({
        header: 'Роль не доступна',
        message: `
        Работа под ролью "${nameRole}" в системе возможна только в мобильном приложении.
            `,
        color: 'primary',
        position: 'middle',
        cssClass: 'nci-toast',
        buttons: [
          {
            text: 'Ясно',
            role: 'cancel',
            handler: () => this.srv.toastPresented$.next(false),
          },
        ],
      })
      .then((t) => {
        t.present();
        this.srv.toastPresented$.next(true);
      });
  }

  public redirect(url: string, target = '_self'): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        resolve(!!this.window.open(url, target));
      } catch (e) {
        reject(e);
      }
    });
  }

  public noteError(header: string, message: string) {
    this.toast
      .create({
        header,
        message,
        color: 'danger',
        position: 'top',
        buttons: [
          {
            text: 'Ясно',
            role: 'cancel',
            handler: () => this.srv.toastPresented$.next(false),
          },
        ],
      })
      .then((t) => {
        t.present();
        this.srv.toastPresented$.next(true);
      });
  }

  public setLayers(layers: IAppLayer[]) {
    this.layersSubject.next([...layers]);
  }

  public addLayer(newLayer: IAppLayer) {
    newLayer.selected = true;
    const layers = [...this.layersSubject.getValue()];
    layers.forEach((l) => (l.selected = false));
    layers.push(newLayer);
    this.layersSubject.next(layers);
  }

  public removeLayer(layer: IAppLayer) {
    const layers = [...this.layersSubject.getValue()];
    let layerId = layers.indexOf(layer);
    if (~layerId) layers.splice(layerId, 1);
    this.layersSubject.next(layers);
  }

  public findEntLayer(entkey, entid): IAppLayer {
    const layers = [...this.layersSubject.getValue()];
    return layers.find(
      (layer) =>
        layer.type === 'entity' && layer.entity && layer.entity.type === entkey && layer.entity.id === String(entid)
    );
  }

  public tryToStepBackToLayer(layer: IAppLayer) {
    let layers = this.layersSubject.value;
    let topLayerId = layers.length - 1;
    let layerId = layers.indexOf(layer);
    if (~layerId && topLayerId > layerId) {
      this.tryToCloseLayer(topLayerId, layerId, layers);
    }
    layer.selected = true;
  }

  public tryToCloseLayer(layerId: number, selectedLayerId: number, layers: IAppLayer[]) {
    let layer = layers[layerId];
    if (layer.modal && layer.modal.componentProps && layer.modal.componentProps)
      layer.modal.componentProps.closer(() => {
        layers.splice(layerId, 1);

        if (layerId - 1 > selectedLayerId) {
          this.tryToCloseLayer(layerId - 1, selectedLayerId, layers);
        }
      });
  }

  public updateIntegrationToken() {
    this.http
      .post('/webapi/v1/token_integration_refresh/', {})
      .pipe(
        map((response) => {
          return response ? response['data'] : null;
        }),
        catchError((e) => {
          console.log('[ERROR]', e);
          let message = 'Сервис недоступен';
          if (e.error) {
            if (e.error.errors) {
              message = e.error.errors.reduce(
                (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                ''
              );
            }
          }
          return from(
            this.toast
              .create({
                header: 'Ошибка доступа',
                message: `${message}`,
                color: 'danger',
                position: 'middle',
                buttons: [
                  {
                    text: 'Ясно',
                    role: 'cancel',
                  },
                ],
              })
              .then((t) => t.present())
              .then(() => {
                throw e;
              })
          );
        })
      )
      .subscribe((token: TokenIntegrationModel) => {
        this.token_integration = token;
        this.toast
          .create({
            message: 'Токен успешно обновлен',
            duration: 2000,
            color: 'success',
            position: 'top',
          })
          .then((t) => t.present());
      });
  }

  /**
   * Возвращает вычисленный доступный остаток
   *
   * @param moneyInfo
   */
  public getMoneyAvailableCalculated(moneyInfo: MoneyInfoModel): number {
    const available = Number(moneyInfo.amount_available);
    const reserved = Number(moneyInfo.amount_reserved);
    const total = Number(moneyInfo.amount_common);

    return available ? available : total - reserved;
  }

  /** Проверяет, если клиент - мобильное устройство */
  public isMobileClient$(): Observable<boolean> {
    return of(
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        navigator.userAgent
      )
    );
  }

  /** Проверяет, если клиент - мобильное устройство посредством ширины экрана */
  public isMobileClientByWindowWidth(): boolean {
    const mobileDeviceMaxWidth = 991;
    return window.innerWidth <= mobileDeviceMaxWidth;
  }

  public getEditorOptionsForMap(form) {
    return {
      type: 'location' as 'location',
      editableCollection: [
        {
          name: 'Расположение места накопления',
          type: 'dot',
          form,
          auto: [0],
        },
      ],
      layersSets: this.LAYERS_SETS,
    };
  }

  public getViewerOptionsForMap(form) {
    return {
      type: 'any',
      state$: new BehaviorSubject({
        items: [
          {
            type: 'geojson',
            geojson: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [form.get('lon').value, form.get('lat').value],
                  },
                  properties: {
                    kind: 'pin',
                    count: 1,
                    enttype: 'wasteplace',
                    statuscode: 'active',
                  },
                },
              ],
            },
          },
        ],
      }),
      maptypes: { wasteplace: 'pin' },
      clusterOptions: {},
      layersSets: this.LAYERS_SETS,
      location$: new BehaviorSubject(null),
    };
  }
}
