import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '@appServices/app.service';
import { catchError, map } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { AuthEsiaModel } from '@appModels/auth-esia-model';
import { AuthService } from '../providers/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EsiaAuthGuard implements CanActivate, CanLoad {
  constructor(private appService: AppService, private navController: NavController, private authService: AuthService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService
      .getAuthState$({
        mainMsg: 'Получение доступа через портал ГосУслуг...',
        errMsg: 'Доступ запрещен',
        hasNoty: true,
      })
      .pipe(
        map((response) => this.checkAuth(response)),
        catchError((e) => {
          throw new Error(e);
        })
      );
  }

  private checkAuth(auth: AuthEsiaModel): boolean {
    let err = null;
    if (!auth || !auth.data) {
      err = { text: 'Данные для аутентификации не получены' };
    } else {
      if (auth.data.error) {
        err = auth.data.error;
      }
    }
    if (err) {
      this.appService.noteAuthError(err);
    }
    this.appService.auth = auth;
    if (auth.data.state === 'auth') {
      this.appService.noteAuthSuccess();
      return true;
    } else if (auth.data.state === 'wait') {
      this.navController.navigateForward('/org');
    } else if (auth.data.state === 'unauth') {
      this.appService.auth = null;
      if (auth.data.url_follow) {
        this.appService.authFollowUrl = auth.data.url_follow;
      }
      this.navController.navigateRoot('/login');
    } else {
      this.appService.auth = null;
      this.navController.navigateRoot('/login');
    }
    return false;
  }
}
