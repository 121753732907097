import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DctService } from './dct.service';

@Pipe({
  name: 'dct',
})
export class DctPipe implements PipeTransform {
  constructor(private dct: DctService) {}

  async transform(code: string, key: string, param = 'name') {
    return await firstValueFrom(this.dct.getParamValue$(key, code, param));
  }
}
