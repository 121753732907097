import { Pipe, PipeTransform } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DctService } from './dct.service';

@Pipe({
  name: 'dctEnt',
})
export class DctEntPipe implements PipeTransform {
  constructor(private dct: DctService) {}

  async transform(code: string, dctkey: string) {
    return await firstValueFrom(this.dct.getEntity$(dctkey, code));
  }
}
