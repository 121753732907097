import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AppService } from '@appServices/app.service';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';
import { AuthEsiaModel } from '@appModels/auth-esia-model';
import { AuthPassModel } from '@appModels/auth-pass-model';
import { AuthTypeModel } from '@appModels/auth-type-model';
import { MessageService } from '@appServices/message.service';

export type MessageParams = { mainMsg: string; hasNoty?: boolean; errMsg?: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private appService: AppService,
    private http: HttpClient,
    private toastController: ToastController,
    private messageService: MessageService
  ) {}

  /**
   * Авторизует пользователя по логину/паролю
   *
   * @param username - логин
   * @param password - пасс
   * @param messageParams - входные параметры для лоадеров
   */
  public authByPass$(username: string, password: string, messageParams: MessageParams): Observable<AuthPassModel> {
    this.messageService.showLoading(messageParams.mainMsg);
    return this.http.post<AuthPassModel>('/webapi/v1/auth/', { username, password }).pipe(
      tap((authRe) => (this.appService.token_integration = authRe.data.token_integration)),
      catchError((e) => this.handleErrors(e, messageParams))
    );
  }

  /**
   * Проверяет состояние авторизации
   *
   * @param messageParams - входные параметры для лоадеров
   */
  public getAuthState$(messageParams: MessageParams, userMode = false): Observable<AuthEsiaModel> {
    this.messageService.showLoading(messageParams.mainMsg);

    let authUrl = '/webapi/v1/auth/';
    if (userMode) {
      authUrl += '?mode=user';
    }
    return this.http.get<AuthEsiaModel>(authUrl).pipe(
      tap((r) => (this.appService.token_integration = r.data.token_integration)),
      catchError((e) => this.handleErrors(e, messageParams))
    );
  }

  /**
   * Получает тип авторизации
   *
   * @param messageParams - входные параметры для лоадеров
   */
  public getAuthType$(messageParams: MessageParams): Observable<AuthTypeModel> {
    this.messageService.showLoading(messageParams.mainMsg);
    return this.http.get<AuthTypeModel>('/webapi/v1/env/').pipe(catchError((e) => this.handleErrors(e, messageParams)));
  }

  /* Обрабатывает ошибки при недоступности авторизации */
  private handleErrors(e, o: MessageParams) {
    let message = o.errMsg || 'Сервис недоступен';

    if (e.error?.errors) {
      message = e.error.errors.reduce(
        (acc, v) => `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
        ''
      );
    }

    if (o.hasNoty) {
      return from(
        this.toastController
          .create({
            header: 'Ошибка доступа',
            message: `${message}`,
            color: 'danger',
            position: 'middle',
            buttons: [
              {
                text: 'Ясно',
                role: 'cancel',
              },
            ],
          })
          .then((t) => t.present())
          .then(() => {
            throw e;
          })
      );
    } else {
      throw e;
    }
  }
}
