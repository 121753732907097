import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EsiaAuthGuard } from './guards/esia-auth.guard';
import { UnauthorizedPage } from './unauthorized/unauthorized.page';
import { SelectOrgsGuard } from './guards/select-orgs.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ws',
    pathMatch: 'full',
  },
  {
    path: 'entrance',
    canActivate: [EsiaAuthGuard],
    loadChildren: () => import('./entrance/entrance.module').then((m) => m.EntrancePageModule),
  },
  {
    path: 'unauthorized',
    component: UnauthorizedPage,
  },
  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then((m) => m.SuccessPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./entrance/entrance.module').then((m) => m.EntrancePageModule),
  },
  {
    path: 'ws',
    canLoad: [EsiaAuthGuard],
    loadChildren: () => import('./ws/ws.module').then((m) => m.WsPageModule),
  },
  {
    path: 'org',
    canActivate: [SelectOrgsGuard],
    loadChildren: () => import('./org/org.module').then((m) => m.OrgPageModule),
  },
  {
    path: 'underconstruction',
    loadChildren: () =>
      import('./underconstruction/underconstruction.module').then((m) => m.UnderconstructionPageModule),
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
