import { Pipe, PipeTransform } from '@angular/core';
import { TermService } from './term.service';

@Pipe({
  name: 'term',
})
export class TermPipe implements PipeTransform {
  constructor(private termService: TermService) {}

  transform(code: string, override?: any): any {
    return this.termService.getTerm(code, override) || code;
  }
}
